<template>
    <div class="iframe-interface">
        <iframe ref="UGC_IFRAME" :src="UGC_URL" frameborder="0" />
    </div>
</template>

<script>
export default {
    name: "IframeInterface",
    data() {
        return {
            UGC_URL: process.env.VUE_APP_UGC_URL,
            MESSAGES: {
                ADD_NEW_BULLET: "ADD_NEW_BULLET",
                FORMAT_TEXT: "FORMAT_TEXT",
                REMOVE_BULLET: "REMOVE_BULLET",
                ON_BULLET_CHANGE: "ON_BULLET_CHANGE",
                ON_BULLET_FOCUS: "ON_BULLET_FOCUS",
                ON_SELECTION_CHANGE: "ON_SELECTION_CHANGE",
                ON_CONTENT_CHANGE: "ON_CONTENT_CHANGE",
                SET_CONTENT: "SET_CONTENT",
                GET_CONTENT: "GET_CONTENT",
                INSERT_IMAGE: "INSERT_IMAGE",
                SET_BULLET_DISPLAY_TYPE: "SET_BULLET_DISPLAY_TYPE",
                INSERT_LINK: "INSERT_LINK",
                ON_TITLE_CHANGE: "ON_TITLE_CHANGE",
                SET_TITLE_CONTENT: "SET_TITLE_CONTENT",
                GET_TITLE_CONTENT: "GET_TITLE_CONTENT",
                ON_MENTION: "ON_MENTION",
                ON_HASHTAG: "ON_HASHTAG",
                ON_BULLET_BLUR: "ON_BULLET_BLUR",
                ON_MENTION_END: "ON_MENTION_END",
                ON_HASHTAG_END: "ON_HASHTAG_END",
                INSERT_MENTION: "INSERT_MENTION",
                INSERT_HASHTAG: "INSERT_HASHTAG",
                INSERT_MEMO_LINK: "INSERT_MEMO_LINK",
                INSERT_TEXT: "INSERT_TEXT",
                INSERT_MENTION_TEXT: "INSERT_MENTION_TEXT",
                INSERT_HASHTAG_TEXT: "INSERT_HASHTAG_TEXT",
                EDITOR_LOADED: "EDITOR_LOADED",
                SET_EDITOR_COLOR: "SET_EDITOR_COLOR"
            },
            contentResolve: undefined,
            contentReject: undefined,
            contentTimeout: undefined,
            setContentResolve: undefined,
            setContentReject: undefined,
            setContentTimeout: undefined
        };
    },
    mounted() {
        window.addEventListener("message", this.onMessage);
    },
    methods: {
        emitMessage(message) {
            this.$refs.UGC_IFRAME.contentWindow.postMessage(JSON.stringify(message), "*")
        },
        addNewBullet() {
            const message = {
                message: this.MESSAGES.ADD_NEW_BULLET,
                args: {}
            };
            this.emitMessage(message);
        },
        formatSelection(format) {
            const message = {
                message: this.MESSAGES.FORMAT_TEXT,
                args: {
                    format
                }
            };
            this.emitMessage(message);
        },
        setBulletDisplayType(type) {
            const message = {
                message: this.MESSAGES.SET_BULLET_DISPLAY_TYPE,
                args: type
            };
            this.emitMessage(message);
        },
        insertImages(images) {
            const message = {
                message: this.MESSAGES.INSERT_IMAGE,
                args: {
                    images
                }
            };
            this.emitMessage(message);
        },
        insertLink(url) {
            const message = {
                message: this.MESSAGES.INSERT_LINK,
                args: url
            };
            this.emitMessage(message);
        },
        insertMention(username) {
            const message = {
                message: this.MESSAGES.INSERT_MENTION,
                args: username
            };
            this.emitMessage(message);
        },
        insertHashtag(tag) {
            const message = {
                message: this.MESSAGES.INSERT_HASHTAG,
                args: tag
            };
            this.emitMessage(message);
        },
        insertText(text) {
            const message = {
                message: this.MESSAGES.INSERT_TEXT,
                args: text
            };
            this.emitMessage(message);
        },
        insertMentionText() {
            const message = {
                message: this.MESSAGES.INSERT_MENTION_TEXT,
                args: undefined
            };
            this.emitMessage(message);
        },
        insertLinkMemo(memo) {
            const message = {
                message: this.MESSAGES.INSERT_MEMO_LINK,
                args: memo
            };

            this.emitMessage(message);
        },
        insertHashtagText() {
            const message = {
                message: this.MESSAGES.INSERT_HASHTAG_TEXT,
                args: undefined
            };
            this.emitMessage(message);
        },
        async setEditorColor(color) {
            const message = {
                message: this.MESSAGES.SET_EDITOR_COLOR,
                args: color
            };
            this.emitMessage(message);
        },
        getContent() {
            const message = {
                message: this.MESSAGES.GET_CONTENT,
                args: {}
            };

            return new Promise((resolve, reject) => {
                this.contentResolve = resolve;
                this.contentReject = reject;
                this.contentTimeout = setTimeout(() => {
                    const err = new Error("timeout");
                    reject(err);
                }, 5000);

                try {
                    this.emitMessage(message);
                } catch (e) {
                    reject(e);
                }
            });
        },
        setContent(storm) {
            const message = {
                message: this.MESSAGES.SET_CONTENT,
                args: {
                    storm
                }
            };

            return new Promise((resolve, reject) => {
                this.setContentResolve = resolve;
                this.setContentReject = reject;
                this.setContentTimeout = setTimeout(() => {
                    const err = new Error("timeout");
                    reject(err);
                }, 5000);

                try {
                    this.emitMessage(message);
                } catch (e) {
                    reject(e);
                }
            });
        },
        onMessage(e) {
            const { data } = e;
            if (data && typeof (data) === "string") {
                const { message, args } = JSON.parse(data);
                switch (message) {
                    case this.MESSAGES.ON_BULLET_CHANGE: {
                        this.$emit("on-bullet-change", args);
                        break;
                    }
                    case this.MESSAGES.ON_TITLE_CHANGE: {
                        this.$emit("on-title-change", args);
                        break;
                    }
                    case this.MESSAGES.ON_BULLET_FOCUS: {
                        this.$emit("on-bullet-focus", args);
                        break;
                    }
                    case this.MESSAGES.ON_BULLET_BLUR: {
                        this.$emit("on-bullet-blur", args);
                        break;
                    }
                    case this.MESSAGES.ON_SELECTION_CHANGE: {
                        this.$emit("on-bullet-selection-change", args);
                        break;
                    }
                    case this.MESSAGES.ON_MENTION: {
                        const username = args;
                        this.$emit("on-mention", username);
                        break;
                    }
                    case this.MESSAGES.ON_HASHTAG: {
                        const tag = args;
                        this.$emit("on-hash-tag", tag);
                        break;
                    }
                    case this.MESSAGES.ON_MENTION_END: {
                        this.$emit("on-mention-end");
                        break;
                    }
                    case this.MESSAGES.ON_HASHTAG_END: {
                        this.$emit("on-hash-tag-end");
                        break;
                    }
                    case this.MESSAGES.ON_CONTENT_CHANGE: {
                        const { bullets } = args;
                        this.$emit("on-change", bullets);
                        if (this.contentResolve) {
                            this.contentResolve(bullets);
                            this.contentResolve = undefined;
                            this.contentReject = undefined;
                            clearTimeout(this.contentTimeout);
                        }

                        if (this.setContentResolve) {
                            this.setContentResolve(),
                            this.setContentReject = undefined,
                            this.setContentTimeout = undefined
                            clearTimeout(this.setContentTimeout);
                        }

                        break;
                    }
                    case this.MESSAGES.EDITOR_LOADED: {
                        this.$emit("on-editor-loaded");
                        break;
                    }
                    default:
                        return null;
                }
            }
            return null;
        }
    }
}
</script>

<style lang="scss" scoped>
.iframe-interface {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media(max-width: $md) {
        padding-top: 15px;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
    }

    iframe {
        width: 100%;
        flex: 1;
    }
}
</style>
